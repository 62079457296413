<template>
  <div class="chat-term">
    <message :data="data" />
    <div
      ref="cardTerm"
      class="card-term">
      <v-card class="box-card">
        <v-card-text>
          <loading
            v-if="loadingTemplate < 1"
            class="absolute-center" />
          <pdf :src="urlTemplate" />
        </v-card-text>
        <p class="text-right mx-3 my-2">
          <a
            href=""
            class="accent--text"
            @click.prevent="openModal">Visualizar Termo</a>
        </p>
      </v-card>
    </div>

    <modal
      :show="showModal"
      hide-actions
      @close="closeModal">
      <div slot="content">
        <pdf :src="urlTemplate" />
      </div>
    </modal>
  </div>
</template>

<script>
  import Message from './Message'
  import chatUiMixin from '@/mixins/chat-ui'
  import Loading from '@/components/ui/Loading'
  import Modal from '@/components/ui/Modal'
  import PdfTemplateModels from '@/mixins/pdf-template-models'

  export default {
    components: { Message, Loading, Modal },
    mixins: [ chatUiMixin, PdfTemplateModels ],
    data () {
      return {
        urlTemplate: '',
        loadingTemplate: 0,
        showModal: false
      }
    },
    beforeMount () {
      this.previewDocument('agree')
    },
    mounted () {
      this.scaleAnimation()
    },
    methods: {
      loadTemplate (status) {
        this.loadingTemplate = status
      },
      previewDocument (kind) {
        this.loadingTemplate = 0
        switch (kind) {
        case 'agree': {
          this.agree()
          break
        }
        case 'disagree': {
          this.disagree()
          break
        }
        }
      },
      openModal () {
        this.showModal = true
      },
      closeModal () {
        this.showModal = false
      },
      scaleAnimation () {
        const cardTerm = this.$refs.cardTerm

        this.anime({
          targets: cardTerm,
          easing: 'easeInOutQuad',
          delay: 500,
          scale: [
            { value: 0, duration: 100 },
            { value: 1, duration: 500 }
          ],
          opacity: [
            { value: 0, duration: 100 },
            { value: 1, duration: 200 }
          ]
        })
      }
    }
  }
</script>

<style lang="sass">
  @import '../../../assets/style/placeholder'

  .chat-term

    .card-term
      opacity: 0
      transform: scale(0)

    .v-card
      margin-bottom: 20px
      max-height: 350px
      padding: 10px 0

    .v-card__text
      max-height: 350px
      overflow-y: auto
      @extend %scroll

      div:nth-child(2) > div
        max-height: 200px


  @media screen and (max-width: $max-mobile)

    .chat-term

      .v-card
        max-height: 250px


    .modal .v-card__text
      padding: 0!important

</style>
